// 恐貪指數說明彈窗
.progress-circle {
  --percent: 0; /* 百分数 */
  --size: 80px; /* 尺寸大小 */
  --border-width: 8px; /* 环宽（粗细） */
  --gap-degree: 90; /* 缺口夹角 */
  --color: #7856d7; /* 主色 */
  --inactive-color: #f7f7f8; /* 背景色 */

  position: relative;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  span {
    font-size: 12px;
    font-weight: 500;
    color: var(--color); /* 主色 */
  }
}

/* 百分数文本 */
.progress-circle::after {
  position: absolute;
  bottom: 6px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  counter-reset: progress var(--percent);
  content: counter(progress);
  white-space: nowrap;
  font-size: 24px;
  font-weight: 700;
  color: #1e1e1e;
}

:is(.dark .progress-circle) {
  &::after {
    color: white;
  }
}

/* SVG 容器 */
.progress-circle > svg {
  width: 100%;
  height: 100%;
  transform: rotate(
    calc((var(--gap-degree) + (180 - var(--gap-degree)) / 2) * 1deg)
  );
}

/* 进度条圆环 */
.progress-circle circle {
  --r: calc((var(--size) - var(--border-width)) / 2);
  --active-degree: calc(360 - var(--gap-degree));
  cx: calc(var(--size) / 2);
  cy: calc(var(--size) / 2);
  r: var(--r);
  fill: none;
  stroke-width: var(--border-width);
  stroke-linecap: round;
}

.circle1 {
  stroke-dasharray: calc(3.1415 * var(--r) * var(--active-degree) / 180),
    calc(3.1415 * var(--r) * var(--gap-degree) / 180);
}

.progress-value {
  stroke-dasharray: calc(
      3.1415 * var(--r) * var(--percent) * var(--active-degree) / 180 / 100
    ),
    1000;
  opacity: var(--percent);
  transition: stroke-dasharray 0.4s linear, stroke 0.3s;
}
