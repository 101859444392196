.news-content {
    font-family: 'Noto Sans TC', sans-serif, 'Roboto Slab' !important;
    max-width: 100%;
    overflow: hidden;
    // 內文
    h2,
    h3,
    h4,
    p,
    i,
    strong,
    ul,
    ol,
    p span {
        color: #1e1e1e;
    }
    p,
    p span {
        margin-bottom: 30px;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 200%; /* 36px */
        letter-spacing: 1.3px;
        word-break: break-word;
        word-wrap: break-word;
    }

    //   h2標題
    h2 {
        position: relative;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        margin: 70px auto 12px;
        @media screen and (max-width: 599px) {
            font-size: 24px;
        }
        &:first-of-type {
            margin-top: 0;
        }
    }
    //   h3標題
    h3 {
        position: relative;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        margin: 70px auto 10px;
        @media screen and (max-width: 599px) {
            font-size: 22px;
        }
    }
    h4 {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        margin: 70px auto 10px;
        @media screen and (max-width: 599px) {
            font-size: 20px;
        }
    }
    h2,
    h3,
    h4 {
        background-color: transparent !important;
        * {
            color: #1e1e1e !important;
        }
        strong {
            font-weight: 700 !important;
        }
    }
    // 列表
    ul,
    ol {
        margin: 30px auto;
        padding-left: 32px;
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        line-height: 200%; /* 36px */
        letter-spacing: 1.3px;
        li {
            margin-bottom: 8px;
        }
    }
    ul li {
        list-style: disc;
    }
    ol li {
        list-style: decimal;
    }
    //   連結
    a {
        transition: 0.3s ease;
        color: #00445d !important;
        text-decoration: underline;
        * {
            transition: 0.3s ease;
            color: #00445d !important;
            &:hover {
                opacity: 0.7;
            }
        }
        &:hover {
            color: #29afcb !important;
            opacity: 0.7;
        }
    }

    //   螢光筆
    .highlight {
        background: linear-gradient(to bottom, transparent 50%, rgba(41, 175, 203, 0.2) 50%);
    }
    strong {
        background: transparent;
    }

    //表格
    figure.table {
        width: 100% !important;
        margin: 30px auto;
        margin-bottom: 30px;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 200%; /* 36px */
        letter-spacing: 1.3px;
        color: #1e1e1e;
        @media screen and (max-width: 767px) {
            overflow-x: scroll;
            overflow-y: hidden;
        }

        table {
            margin: 0 auto;
            position: relative;
            z-index: 0;
            width: 100%;
            color: #1e1e1e;
            @media screen and (max-width: 767px) {
                width: max-content !important;
                thead {
                    white-space: pre;
                }
            }
        }

        td {
            p:last-of-type {
                margin-bottom: 0;
            }
        }

        tr,
        td {
            padding: 10px 15px;
            border: 1px solid #e5e5e5;

            ul,
            ol {
                font-weight: 300 !important;
                margin: 0 auto !important;

                li:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        tr > td:nth-of-type(1) {
            width: 22%;
            @media screen and (max-width: 599px) {
                width: 30%;
            }
            @media screen and (max-width: 374px) {
                width: 45%;
            }
        }

        tr > th {
            padding: 10px 15px;
        }
    }

    .iframely-responsive {
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        position: relative;
        padding-bottom: 56.25%;
    }

    .iframely-responsive > * {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border: 0;
    }

    // 圖片
    p {
        img {
            width: 100%;
            display: block;
            margin: 40px auto;
            border-radius: 6px;
        }
    }

    figure.media {
        margin: 40px auto 70px;
        @media screen and (max-width: 599px) {
            margin: 30px auto 45px;
        }
        @media screen and (max-width: 374px) {
            margin: 30px auto;
        }
    }

    figure.image {
        position: relative;
        margin: 40px auto;
        text-align: center;
        width: fit-content;
        width: 100%;
        &.image_resized {
            width: unset;
        }
        @media screen and (max-width: 599px) {
            width: 100% !important;
        }
        @media screen and (max-width: 374px) {
            margin: 30px auto;
        }
        > img {
            margin: 0 auto;
            width: 100%;
            border-radius: 6px;
            @media screen and (max-width: 1023px) {
                width: 100% !important;
            }
        }

        figcaption {
            display: flex;
            align-items: flex-start;
            text-align: left;
            flex-direction: column;
            position: relative;
            top: -35px;
            width: 100%;
            row-gap: 5px;
            //圖源
            a {
                display: flex !important;
            }

            .img-source {
                padding: 6px 11px;
                display: inline-block;
                background: rgba(62, 58, 57, 0.58);
                color: white;
                backdrop-filter: blur(20px) !important;
                border-radius: 0px 6px;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px; /* 176.923% */
                letter-spacing: 0.25px;
            }

            // 圖說
            .img-speak {
                color: #c7c7c7;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 15.6px */
                letter-spacing: 1.25px;
            }
        }
    }

    figure.image:has(span) {
        margin: 40px auto 10px;
        @media screen and (max-width: 374px) {
            margin: 30px auto 10px;
        }
    }

    //大引言
    .bigquote {
        display: block;
        position: relative;
        z-index: 0;
        margin: 70px auto !important;
        color: #1e1e1e !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 350;
        line-height: 200%; /* 32px */
        letter-spacing: 1.3px;
        width: 100%;
        padding: 20px 20px 20px 88px;
        border-radius: 2px;
        background-color: #f7f7f8;
        &::before {
            content: '';
            position: absolute;
            width: 43px;
            height: 32px;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin: auto;
            z-index: 1;
            background: url('/images/icon/icon_quote.svg');
            background-size: contain;
        }
    }

    //小引言
    .smallquote {
        display: block;
        text-align: left;
        width: 80%;
        margin: 60px 90px;
        position: relative;
        z-index: 0;
        left: 0;
        right: 0;
        margin: 30px auto;
        text-align: center;
        padding: 80px 20px;
        color: #1e1e1e !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 200%; /* 32px */
        letter-spacing: 1.3px;
        @media screen and (max-width: 1023px) {
            width: 90%;
        }
        @media screen and (max-width: 767px) {
            width: 95%;
            padding: 70px 20px;
        }

        @media screen and (max-width: 599px) {
            text-align: left;
        }

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 29%;
        }

        &::after {
            background: url('/images/news/border_top_mark.svg') no-repeat center;
            background-size: 100%;
            bottom: 0;
        }

        &::before {
            background: url('/images/news/border_bot_mark.svg') no-repeat center;
            background-size: 100%;
            top: 0;
        }
    }

    // 相關連結
    p:has(.mulrecom) {
        position: relative;
        margin: 60px auto;
        padding: 20px 20px 15px;
        border: 1px solid #f7f7f8;
        border-radius: 2px;
        @media screen and (max-width: 599px) {
            margin: 40px auto;
        }
        // &::after {
        //   position: absolute;
        //   top: 20px;
        //   left: 20px;
        //   content: var(--related);
        //   font-size: 20px;
        //   font-style: normal;
        //   font-weight: 700;
        //   line-height: normal;
        //   letter-spacing: 1.3px;
        //   color: #141414;
        //   display: block;
        //   clear: both;
        // }
        // 連結
        .mulrecom {
            display: inline-block;
            overflow-wrap: break-word;
            margin-bottom: 10px;
            width: 100%;
            position: relative;
            padding-left: 22px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 200%; /* 28.8px */
            letter-spacing: 0.25px;
            text-decoration-line: underline;
            transition: 0.3s ease;
            &:hover {
                opacity: 0.7;
            }
            & + br {
                display: none;
            }
        }
        .mulrecom::before {
            content: '•';
            position: absolute;
            left: 5px;
        }

        a {
            & + br {
                display: none;
            }
        }
        > span {
            text-decoration: none !important;
            color: #1e1e1e;
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            letter-spacing: 1.3px !important;
            padding: 0 !important;
            &::before {
                content: none !important;
            }
        }
    }

    // 推薦閱讀
    p:has(.recommend) {
        position: relative;
        margin: 60px auto;
        @media screen and (max-width: 599px) {
            margin: 40px auto;
        }
        > .recommend {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.3px;
            color: #141414;
        }
        a > .recommend {
            display: inline-block;
            overflow-wrap: break-word;
            margin-bottom: 7px;
            width: 100%;
            position: relative;
            font-size: 16px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 180%; /* 28.8px */
            letter-spacing: 0.25px;
            text-decoration-line: underline;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        a {
            transition: 0.3s ease-in;
            &:hover {
                opacity: 0.7;
            }
            span {
                font-weight: 400 !important;
            }
            & + br {
                display: none;
            }
        }

        // 清除漂浮
        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    hr {
        position: absolute;
        opacity: 0;
        z-index: -1;
        top: -70px;
    }
    p:has(.black-button),
    p:has(.white-button),
    p:has(.blue-button) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    // 底色背景按鈕連結
    a:has(.black-button),
    a:has(.white-button),
    a:has(.blue-button) {
        line-height: 0 !important;
        span {
            font-size: 13px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 120% !important; /* 15.6px */
            letter-spacing: 1.25px !important;
            display: inline-block;
            position: relative;
            bottom: 1px;
            padding: 9px 18px 7px;
            margin-bottom: 0 !important;
        }
        border-radius: 2px !important;
        display: inline-block;
        text-decoration: none !important;
        transition: 0.3s ease;
        &:hover {
            opacity: 0.7;
        }
    }
    a:has(.black-button) {
        background: #1e1e1e !important;
        span {
            color: white !important;
        }
    }
    a:has(.blue-button) {
        background: #29afcb !important;
        span {
            color: white !important;
        }
    }
    a:has(.white-button) {
        border: 1px solid #1e1e1e !important;
        background: white !important;
        span {
            color: #1e1e1e !important;
        }
    }

    p > a {
        &:first-of-type {
            margin-left: 0 !important;
        }
    }

    // 分隔線
    p:has(.separate) {
        margin: 60px auto;
        @media screen and (max-width: 599px) {
            margin: 40px auto;
        }
        span {
            margin: 0 auto !important;
            display: inline-block;
            height: 1px;
            width: 100%;
            border-top: 1px solid rgba(199, 199, 199, 0.5);
        }
    }

    p {
        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }
}

:is(.dark .news-content) {
    h2,
    h3,
    h4,
    p,
    i,
    strong,
    ul,
    ol,
    p span {
        color: #f7f7f8;
    }
    h2,
    h3,
    h4 {
        * {
            color: #f7f7f8 !important;
        }
    }
    a {
        color: #91ffff !important;
        * {
            color: #91ffff !important;
        }
    }
    figure.table {
        color: #f7f7f8;
        table {
            color: #f7f7f8;
        }
    }
    figcaption {
        // 圖說
        .img-speak {
            color: #b0b0b0 !important;
        }
    }
    .bigquote {
        background-color: #393939;
    }
    .smallquote {
        color: #f7f7f8 !important;
    }

    // 相關連結
    p:has(.mulrecom) {
        border: 1px solid #393939;
        &::after {
            color: #ffffff;
        }
    }

    // 推薦閱讀
    p:has(.recommend) {
        > .recommend {
            color: #ffffff;
        }
    }

    a:has(.black-button) {
        background: white !important;
        span {
            color: #1e1e1e !important;
        }
    }

    a:has(.white-button) {
        border: 1px solid white !important;
        background: #1e1e1e !important;
        span {
            color: white !important;
        }
    }
    .bigquote {
        color: #f7f7f8 !important;
        &::before {
            opacity: 0.3;
        }
    }

    //   螢光筆
    .highlight {
        background: linear-gradient(to bottom, transparent 50%, #91ffff50 50%);
    }
}
