@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

:root {
    --trail-color: black;
}

html.dark {
    --trail-color: white;
}
