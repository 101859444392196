@import url('swiper/css');
@import url('swiper/css/pagination');

.swiper-pagination {
    width: fit-content;
    span {
        height: 5px;
        width: 13px;
        border-radius: 40px;
        &.swiper-pagination-bullet-active {
            width: 19px;
        }
    }
}

// 首頁快訊輪播
#flash-swiper {
    .swiper-pagination {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 20px;
        text-align: left;
        span {
            &.swiper-pagination-bullet-active {
                background: #393939;
            }
        }
    }
}

// 首頁 banner 輪播
#index-banner-swiper {
    .swiper-pagination {
        position: absolute;
        bottom: 10px;
        padding-right: 10px;
        width: 100%;
        height: 20px;
        text-align: right;
        @media screen and (max-width: 1023px) {
            top: 49vw;
        }

        span {
            background: white;
            opacity: 0.3;
            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}

#index-banner-container {
    &.act {
        @media screen and (max-width: 1023px) {
            margin-top: 34px;
        }
    }
}

// 活動輪播
#events-swiper {
    .swiper-slide {
        aspect-ratio: 2/1 !important;
    }
}

#news-swiper {
    .swiper-wrapper {
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        position: relative;
        margin-top: 10px;
        width: 100%;
        height: 20px;
        text-align: center;
        span {
            &.swiper-pagination-bullet-active {
                background: #393939;
            }
        }
    }
}

:is(.dark #flash-swiper) {
    .swiper-pagination {
        span {
            background: grey;
        }
    }
}

:is(.dark #news-swiper) {
    .swiper-pagination {
        span {
            background: #f8f8f8;
        }
    }
}

// cr 輪播
#about-swiper,
#team-swiper,
#media-swiper {
    padding-bottom: 30px;
    .swiper-pagination {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 20px;
        text-align: center;
        span {
            &.swiper-pagination-bullet-active {
                background: #393939;
            }
        }
    }
}

// 學院 banner 輪播
#academy-banner-swiper {
    .swiper-pagination {
        bottom: 0 !important;
        padding: 7px 0 17px;
        @media screen and (max-width: 1379px) {
            background: none;
        }
    }
    .swiper-pagination-bullet {
        width: 12.5px;
        height: 5px;
        border-radius: 40px;
        opacity: unset !important;
        background: rgba(134, 134, 134, 0.3);
        &.swiper-pagination-bullet-active {
            width: 19px;
            background: rgb(134, 134, 134);
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 45%;
        width: 30px;
        height: 30px;
        z-index: 1;
        background-position: center;
        background-size: contain;
        cursor: pointer;
        @media screen and (max-width: 1379px) {
            display: none;
        }
    }
    .swiper-button-prev {
        left: 15px;
        background-image: url('/images/icon/icon_arrow_right.svg');
    }
    .swiper-button-next {
        background-image: url('/images/icon/icon_arrow_left.svg');
        right: 15px;
    }
}
